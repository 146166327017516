import React from "react";
import { Toast, ToastHeader } from "react-bootstrap";
import useAlertStore from "../../stores/useAlertStore/useAlertStore";

function AlertProvider() {
  const { message, setAlert } = useAlertStore();

  const handleClose = () => {
    setAlert(null);
  };

  return (
    <div className="position-absolute toast-container">
      <Toast
        className="error-toast"
        onClose={handleClose}
        show={message !== null}
        delay={4000}
        autohide
      >
        <ToastHeader>
          <strong className="me-auto">Error</strong>
        </ToastHeader>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default AlertProvider;
