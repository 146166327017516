import useConfigStore from "../../stores/useConfigStore/useConfigStore";

function usePartnerName() {
  const { config } = useConfigStore();

  const name = config.find((v) => v.key === "partner.name")?.value ?? "";

  return name;
}

export default usePartnerName;
