import { ApiClient, AuthClient } from "./client";
import {
  EligibilityFormResponse,
  EligibilityFormSubmit,
  LoginSubmit,
} from "./queries.types";

export const checkEligibility = async (
  form: EligibilityFormSubmit
): Promise<EligibilityFormResponse> => {
  try {
    const resp = await ApiClient.post(`/app/eligibility/check`, {
      request: form,
    });
    return resp.data as EligibilityFormResponse;
  } catch (e) {
    throw new Error("Eligibility check error");
  }
};

export const login = async (values: LoginSubmit): Promise<void> => {
  try {
    await AuthClient.post(`/api/v1/login`, values);
    return;
  } catch (e) {
    throw new Error("Login error");
  }
};
