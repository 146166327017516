import React from "react";
import { Button, Container, Stack } from "react-bootstrap";
import SilverSneakers from "../../assets/SSFP_logotype.svg";
import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import usePartnerName from "../../hooks/usePartnerName/usePartnerName";

function PartialConfirm() {
  const { config } = useConfigStore();
  const name = usePartnerName();
  const fallback =
    config.find((v) => v.key === "eligibility.fallbackUrl")?.value ?? "";

  const handleFallback = () => {
    if (fallback !== "") {
      window.location.replace(`${fallback}?status=FOUND_NOT_ELIGIBLE`);
    }
  };

  return (
    <Container className="success-container">
      <div className="d-flex justify-content-center">
        <img
          src={SilverSneakers}
          alt="SilverSneakers"
          width="40%"
          style={{ marginBottom: "60px" }}
        />
      </div>
      <Stack className="align-items-center" gap={3}>
        <h4 className="text-center">
          <b>Thanks for being a SilverSneakers&reg; member</b>
        </h4>
        <p className="text-center">
          Unfortunately, your health plan does not include {name} at this time.
          <br />
          Check out the many perks your SilverSneakers membership does give you
          by visiting{" "}
          <a
            href="https://tools.silversneakers.com/"
            target="_blank"
            rel="noreferrer"
          >
            silversneakers.com
          </a>
        </p>
        <Button
          variant="primary"
          className="form-button"
          style={{ maxWidth: "300px" }}
          onClick={handleFallback}
        >
          <b>Return To {name}</b>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
            style={{ marginBottom: "4px" }}
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </Button>
      </Stack>
    </Container>
  );
}

export default PartialConfirm;
