import React from "react";
import { Container, Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DeclinedImg from "../../assets/exclamation-circle.svg";

function NotFound() {
  return (
    <Container className="decline-container">
      <div className="d-flex justify-content-center">
        <img
          src={DeclinedImg}
          alt="Declined"
          width="50%"
          style={{ marginBottom: "40px" }}
        />
      </div>
      <Stack className="align-items-center" gap={3}>
        <h4 className="text-center">
          <b>404 Not Found</b>
        </h4>
        <h6 className="text-secondary text-center mt-3">
          <NavLink to="/">Return Home</NavLink>
        </h6>
      </Stack>
    </Container>
  );
}

export default NotFound;
