import React from "react";
import { Card } from "react-bootstrap";
import EligibilityForm from "../../components/EligibilityForm/EligibilityForm";
// import Logo from "../../assets/silversneakers.svg";
import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import ClientLogo from "../../components/ClientLogo/ClientLogo";

function Eligibility() {
  const { config } = useConfigStore();

  const scope = config.find((v) => v.key === "eligibility.scope")?.value ?? "";
  const qualifier =
    config.find((v) => v.key === "eligibility.qualifier")?.value ?? "";

  return (
    <Card className="form-container shadow border-0">
      <Card.Body>
        <ClientLogo />
        <Card.Text className="mt-2">
          Welcome! Please complete a quick eligibility check to see if your
          Medicare Advantage or Medicare Supplement plan includes
          SilverSneakers&reg;.
        </Card.Text>
        <Card.Text>
          Please enter your information below exactly as it appears on your
          health plan ID card.
        </Card.Text>
        <EligibilityForm scope={scope} qualifier={qualifier} />
        <Card.Text className="d-flex justify-content-center mt-2">
          {/* <span className="subtext">
            Already have an account? <a href="/login"> Log in</a>
          </span> */}
        </Card.Text>
        <Card.Text className="d-flex justify-content-center mt-2">
          <span className="subtext">
            By clicking &quot;Check Eligibility&quot;, you are indicating that
            you have read and acknowledge the{" "}
            <a
              href="https://www.silversneakers.com/privacy-policy-terms/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy and Terms of Use
            </a>
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Eligibility;
