import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useClientStore from "../../stores/useClientStore/useClientStore";
import NotFound from "../../pages/NotFound/NotFound";

function ClientSetter() {
  const { pathname } = useLocation();
  const { setClient, client } = useClientStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!client) {
      const cleanPathname = pathname.replace("/", "");
      setClient(cleanPathname);
      navigate("/");
    }
  }, [client, pathname, navigate, setClient]);
  return <NotFound />;
}

export default ClientSetter;
