import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Router from "./routers/Router/Router";
import useConfigStore from "./stores/useConfigStore/useConfigStore";
import useBootStore from "./stores/useBootStore/useBootStore";
import useClientStore from "./stores/useClientStore/useClientStore";
import Loading from "./components/Loading/Loading";
import AlertProvider from "./components/AlertProvider/AlertProvider";
import ClientDoesNotExist from "./components/ClientDoesNotExist/ClientDoesNotExist";

function App() {
  const { getConfig } = useConfigStore();
  const { getBoot } = useBootStore();
  const { client } = useClientStore();

  const {
    isLoading: bootLoading,
    isError: bootError,
    data,
  } = useQuery(["boot"], getBoot, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading,
    isFetching,
    isError: configError,
  } = useQuery(["config"], () => getConfig(client ?? ""), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(data && client),
    retry: 0,
  });

  const loading = bootLoading || (isLoading && isFetching);
  const error = bootError || configError;

  if (loading) {
    return <Loading />;
  }

  if (configError) {
    sessionStorage.clear();
    return <ClientDoesNotExist />;
  }

  if (error) {
    return <h6>Error</h6>;
  }

  return (
    <>
      <AlertProvider />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Router />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
