import React from "react";
import useConfigStore from "../../stores/useConfigStore/useConfigStore";

function ClientLogo() {
  const { config } = useConfigStore();

  const logo = config.find((v) => v.key === "partner.logo")?.value ?? "";
  return (
    <div className="d-flex justify-content-center">
      <img
        src={`data:image/svg+xml;base64,${logo}`}
        alt="logo"
        className="mw-100 text-center"
      />
    </div>
  );
}

export default ClientLogo;
