import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Eligibility from "../../pages/Eligibility/Eligibility";
import MasterLayout from "../../layouts/MasterLayout/MasterLayout";
import Success from "../../pages/Success/Success";
import ClientSetter from "../../components/ClientSetter/ClientSetter";
import ClientRouteGuard from "../../components/ClientRouteGuard/ClientRouteGuard";
import Declined from "../../pages/Declined/Declined";
import PartialConfirm from "../../pages/PartialConfirm/PartialConfirm";

function Router() {
  return (
    <Routes>
      <Route
        element={
          <ClientRouteGuard>
            <MasterLayout />
          </ClientRouteGuard>
        }
      >
        <Route index element={<Eligibility />} />
        <Route path="/authorize" element={<Navigate to="/" />} />
        <Route path="/success" element={<Success />} />
        <Route path="/declined" element={<Declined />} />
        <Route path="/partial/success" element={<PartialConfirm />} />
      </Route>
      <Route element={<MasterLayout />}>
        <Route path="/*" element={<ClientSetter />} />
      </Route>
    </Routes>
  );
}

export default Router;
