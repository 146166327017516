import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { EligibilityFormTypes } from "./EligibilityForm.types";
import { getDays, getMonths, getYears } from "../../util/util";
import {
  EligibilityFormResponse,
  EligibilityFormSubmit,
} from "../../api/queries.types";
import { checkEligibility } from "../../api/queries";
import useTokenStore from "../../stores/useTokenStore/useTokenStore";
import STATUS from "../../pages/Eligibility/Eligibility.types";
import useAlertStore from "../../stores/useAlertStore/useAlertStore";
import ALERTS from "../../util/Alerts";

const schema = yup.object({
  firstName: yup.string().min(2).required(),
  lastName: yup.string().min(2).required(),
  day: yup.string().required(),
  month: yup.string().required(),
  year: yup.string().required(),
  email: yup.string().email().required(),
  // subscribeNewsletter: yup.boolean(),
  zipCode: yup.string().required(),
});

interface IEligibilityFormProps {
  scope: string;
  qualifier: string;
}

function EligibilityForm({ scope, qualifier }: IEligibilityFormProps) {
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { setAlert } = useAlertStore();
  const checkEligibilityMutation = useMutation<
    EligibilityFormResponse,
    any,
    EligibilityFormSubmit,
    any
  >((form) => checkEligibility(form), {
    onSuccess: (data) => {
      if (data.status === STATUS.FOUND_ELIGIBLE) {
        setToken(data.eligibilityToken);
        navigate("/success");
      } else if (data.status === STATUS.FOUND_NOT_ELIGIBLE) {
        navigate("/partial/success");
      } else {
        navigate("/declined");
      }
    },
    onError: () => {
      setAlert(ALERTS.ERROR);
    },
    retry: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EligibilityFormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      day: "01",
      month: "01",
      year: "2000",
      zipCode: "",
      // subscribeNewsletter: true,
    },
  });
  // removed news letter
  const convertToSubmitForm = (
    values: EligibilityFormTypes
  ): EligibilityFormSubmit => ({
    firstName: values.firstName,
    lastName: values.lastName,
    zip: values.zipCode,
    dob: `${values.month}/${values.day}/${values.year}`,
    scope,
    qualifier,
    email: values.email,
    // subscribeNewsletter: values.subscribeNewsletter,
  });

  const handleFormSubmit = handleSubmit((values) => {
    const mappedValues = convertToSubmitForm(values);
    checkEligibilityMutation.mutate(mappedValues);
  });

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="form-spacing">
        <Form.Label>First Name:</Form.Label>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={Boolean(errors.firstName)}
              placeholder="First Name"
            />
          )}
        />
      </Form.Group>
      <Form.Group className="form-spacing">
        <Form.Label>Last Name:</Form.Label>
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={Boolean(errors.lastName)}
              placeholder="Last Name"
            />
          )}
        />
      </Form.Group>
      <Form.Group className="form-spacing">
        <Form.Label>Date of Birth:</Form.Label>
        <Row className="justify-content-between">
          <Col>
            <Controller
              control={control}
              name="month"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Select
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.month)}
                  placeholder="Month"
                >
                  {getMonths()}
                </Form.Select>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={control}
              name="day"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Select
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.day)}
                  placeholder="Day"
                >
                  {getDays()}
                </Form.Select>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={control}
              name="year"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Select
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.year)}
                  placeholder="Year"
                >
                  {getYears()}
                </Form.Select>
              )}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="form-spacing">
        <Form.Label>5 Digit Zip Code:</Form.Label>
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={Boolean(errors.zipCode)}
              placeholder="Zip Code"
            />
          )}
        />
      </Form.Group>
      <Form.Group className="form-spacing">
        <Form.Label>Email Address:</Form.Label>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={Boolean(errors.email)}
              placeholder="Email"
            />
          )}
        />
      </Form.Group>
      {/* <Controller
        control={control}
        name="subscribeNewsletter"
        render={({ field: { onChange, value, ref } }) => (
          <Form.Check
            className="form-spacing"
            type="checkbox"
            onChange={onChange}
            checked={value}
            ref={ref}
            isInvalid={Boolean(errors.subscribeNewsletter)}
            label="Sign up for our newsletter"
          />
        )}
      /> */}
      <Button variant="primary" type="submit" className="form-button">
        <b>Check Eligibility</b>
      </Button>
    </Form>
  );
}

export default EligibilityForm;
