import useBootStore from "../stores/useBootStore/useBootStore";
// eslint-disable-next-line import/no-cycle
import useConfigStore from "../stores/useConfigStore/useConfigStore";

const axios = require("axios");

export const ApiClient = axios.create();
// ApiClient.defaults.withCredentials = true;

ApiClient.interceptors.request.use(
  (config: any) => {
    const bootStore = useBootStore.getState().boot;

    if (bootStore) {
      // eslint-disable-next-line no-param-reassign
      config.baseURL = bootStore.apiUrl;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

export const AuthClient = axios.create();
AuthClient.defaults.withCredentials = true;

AuthClient.interceptors.request.use(
  (c: any) => {
    const { config } = useConfigStore.getState();

    if (config) {
      const idpIssuer = config.find((v) => v.key === "idp.issuer");
      if (idpIssuer) {
        // eslint-disable-next-line no-param-reassign
        c.baseURL = idpIssuer.value;
      }
    }
    return c;
  },
  (error: any) => Promise.reject(error)
);
