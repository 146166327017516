import React from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DeclinedImg from "../../assets/exclamation-circle.svg";
import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import usePartnerName from "../../hooks/usePartnerName/usePartnerName";

function Declined() {
  const { config } = useConfigStore();
  const name = usePartnerName();
  const navigate = useNavigate();

  const fallback =
    config.find((v) => v.key === "eligibility.fallbackUrl")?.value ?? "";

  const handleFallback = () => {
    if (fallback !== "") {
      window.location.replace(`${fallback}?status=NOT_FOUND`);
    }
  };

  return (
    <Container className="decline-container">
      <div className="d-flex justify-content-center">
        <img
          src={DeclinedImg}
          alt="Declined"
          width="20%"
          style={{ marginBottom: "40px" }}
        />
      </div>
      <Stack className="align-items-left" gap={3}>
        <h4 className="text-center">
          <b>We couldn&apos;t find you, but you still might be eligible!</b>
        </h4>
        <h6 className="text-align-left mb-0">
          <b>Why your eligibility check might fail</b>
        </h6>
        <ul>
          <li>
            The name you provided is different than the one your health plan has
            on file
          </li>
          <li>
            The zip code you provided is different than the one your health plan
            has on file
          </li>
          <li>
            You have a new health plan, but the coverage hasn&apos;t started yet
          </li>
        </ul>
        <p className="text-align-left">
          Your health plan doesn&apos;t include SilverSneakers&reg;. Please
          recheck your eligibility or reach out to your health plan and tell
          them you want SilverSneakers today.
        </p>

        <Row className="justify-content-around">
          <Col xs={12} md className="mb-2">
            <Button
              variant="outline-primary"
              className="form-button"
              onClick={() => navigate("/")}
            >
              <b>Try Again</b>
            </Button>
          </Col>
          <Col xs={12} md>
            <Button
              variant="primary"
              className="form-button"
              style={{ whiteSpace: "nowrap" }}
              onClick={handleFallback}
            >
              <b>Return to {name}</b>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
                style={{ marginBottom: "4px" }}
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </Button>
          </Col>
        </Row>
      </Stack>
    </Container>
  );
}

export default Declined;
