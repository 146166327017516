import React from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import Congratulations from "../../assets/confetti_illustration.svg";
import { LoginSubmit } from "../../api/queries.types";
import { login } from "../../api/queries";
import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import useTokenStore from "../../stores/useTokenStore/useTokenStore";
import useAlertStore from "../../stores/useAlertStore/useAlertStore";
import ALERTS from "../../util/Alerts";
import usePartnerName from "../../hooks/usePartnerName/usePartnerName";

function Success() {
  const name = usePartnerName();
  const { config } = useConfigStore();
  const { token } = useTokenStore();
  const { setAlert } = useAlertStore();
  const loginMutation = useMutation<any, any, LoginSubmit, any>(
    (values) => login(values),
    {
      onSuccess: () => {
        const idpIssuer = config.find((v) => v.key === "idp.issuer");
        if (idpIssuer) {
          window.location.replace(`${idpIssuer.value}/login/callback`);
        } else {
          setAlert(ALERTS.ERROR);
        }
      },
      onError: () => {
        setAlert(ALERTS.ERROR);
      },
    }
  );

  const handleComplete = () => {
    if (token) {
      loginMutation.mutate({ username: token, password: "" });
    } else {
      setAlert(ALERTS.ERROR);
    }
  };

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Container className="success-container">
      <div className="d-flex justify-content-center">
        <img
          src={Congratulations}
          alt="congratulations"
          style={{ marginBottom: "90px" }}
        />
      </div>
      <Stack className="align-items-center" gap={3}>
        <h4 className="text-center">
          <b>Congratulations! Your plan includes {name} classes!</b>
        </h4>
        <p className="text-center">
          Celebrate by completing enrollment in {name} then join as many
          fun-filled classes as you like as part of your SilverSneakers&#174;
          membership.
        </p>
        <div>
          <Button
            variant="primary"
            className="form-button"
            style={{ maxWidth: "300px" }}
            onClick={handleComplete}
          >
            <b>Return to {name}</b>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
              style={{ marginBottom: "4px" }}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </Button>
        </div>
      </Stack>
    </Container>
  );
}

export default Success;
