import React from "react";
import useClientStore from "../../stores/useClientStore/useClientStore";
import ClientDoesNotExist from "../ClientDoesNotExist/ClientDoesNotExist";

interface IClientRouteGuard {
  children: JSX.Element;
}

function ClientRouteGuard({ children }: IClientRouteGuard) {
  const { client } = useClientStore();

  if (!client) {
    return <ClientDoesNotExist />;
  }
  return children;
}

export default ClientRouteGuard;
