import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col, Stack } from "react-bootstrap";

function MasterLayout() {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center align-items-md-center pt-2 pt-md-0">
        <Col xs="auto">
          <Stack className="align-items-center">
            <Outlet />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default MasterLayout;
