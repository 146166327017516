import create from "zustand";
import { persist } from "zustand/middleware";

const useClientStore = create(
  persist<{
    client: string | null;
    setClient: (client: string) => void;
  }>(
    (set) => ({
      client: null,
      setClient: (client: string) => {
        set({
          client,
        });
      },
    }),
    {
      name: "client-store",
      getStorage: () => sessionStorage,
    }
  )
);

export default useClientStore;
