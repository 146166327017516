import create from "zustand";
import { BootJson } from "./useBootStore.types";

const axios = require("axios").default;

const getBootJson = async (): Promise<Record<string, any>> => {
  try {
    const resp = await axios.get(`/boot.json`);
    return resp.data as Record<string, any>;
  } catch (e) {
    throw new Error("Could not get boot.json");
  }
};

const useBootStore = create<{
  boot: BootJson | null;
  getBoot: () => Promise<void>;
}>((set) => ({
  boot: null,
  getBoot: async (): Promise<any> => {
    set({
      boot: await getBootJson(),
    });
    return {};
  },
}));

export default useBootStore;
